<template>
  <div class="search">
    <div class="search-nav">
      <div class="index-top">
        <img
          src="../../../assets/indexImage/logo.png"
          alt=""
          key=""
          @click="goIndex"
        />
        <!-- <div class="top-search">
        <input
          type=""
          placeholder="请输入文字"
          v-model="searchVal"
          @keydown.enter="goSearch"
        />
        <img src="../../../assets/indexImage/search.png" alt="" @click="goSearch" />
      </div> -->
        <div class="top-link">
          <div>
            <img src="../../../assets/indexImage/shouji.png" alt="" />
            <span>{{ Phone }}</span>
          </div>
          <div>
            <img src="../../../assets/indexImage/dianhua.png" alt="" />
            <span>{{ cz }}(含传真)</span>
          </div>
          <div>
            <img src="../../../assets/indexImage/emil.png" alt="" />
            <span>{{ emil }}</span>
          </div>
        </div>
      </div>
      <div class="index-navigation">
        <span
          v-for="(item, index) in navigator"
          :key="index"
          @click="goPath(index)"
          >{{ item }}</span
        >
      </div>
      <div class="search-top">
        <img src="../../../assets/searchImg/logo.png" alt="" />
        <div>
          <input type="text" v-model="searchVal" @keydown.enter="search" />
          <span @click="search">搜索</span>
        </div>
      </div>
      <div v-if="itemList.length > 0" class="list">
        <span> 大约有{{ itemList.length }}项符合查询结果</span>
      </div>
      <div v-else class="noList" style="margin-top: 200px">
        暂无找到相关信息
      </div>
      <div class="search-list">
        <div
          class="item-list"
          v-for="item in itemList"
          :key="item.id"
          @click="goDetail(item.url, item.id)"
        >
          <div>
            <span>{{ item.title }}</span>
          </div>
          <span>{{ item.createtime }} 来源: {{ item.source }}</span>
          <div>
            <span>{{ item.brief }}</span>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from "../../../components/Footer.vue";
export default {
  name: "Search",
  data() {
    return {
      PageSize: 8,
      navigator: [
        "首页",
        "资本热点",
        "资本人物",
        "资本动态",
        "资本影视",
        "乡村振兴",
        "健康生活",
        "投诉维权",
      ],
      Phone: "",
      cz: "",
      emil: "",
      searchVal: "",
      itemList: [],
    };
  },
  created() {
    console.log(this.$route);
    this.searchVal = this.$route.query.searchVal;
    this.getData();
    this.getItem();
  },
  methods: {
    getData() {
      this.$http({
        url: "/index/search",
        params: {
          keyword: this.searchVal,
        },
      }).then((res) => {
        console.log(res, "sss");
        this.itemList = res.data.list;
        const search = r;
      });
    },
    search() {
      this.getData();
    },
    goPath(i) {
      if (i == 0) {
        this.$router.push("/index");
      } else if (i == 1) {
        this.$router.push("/hot");
      } else if (i == 2) {
        this.$router.push("/figure");
      } else if (i == 3) {
        this.$router.push("/dynamic");
      } else if (i == 4) {
        this.$router.push("/video");
      } else if (i == 5) {
        this.$router.push("/rural");
      } else if (i == 6) {
        this.$router.push("/healthylife");
      } else if (i == 7) {
        this.$router.push("/complaints");
      }
    },
    goDetail(url, id) {
      if (url == "anli/detail") {
        this.$router.push(`/figuredetails/${id}`);
      }
      if (url == "caijing/detail") {
        this.$router.push(`/hotdetails/${id}`);
      }
      if (url == "xingwen/detail") {
        this.$router.push(`/dynamicdetails/${id}`);
      }
      if (url == "lilun/detail") {
        this.$router.push(`/ruraldetail/${id}`);
      }
      if (url == "yingxiang/details") {
        this.$router.push(`/videodetails/${id}`);
      }
      if (url == "todayhot/detail") {
        this.$router.push(`/daydetails/${id}`);
      }
    },
    goIndex() {
      this.$router.push("/index");
    },
    getItem() {
      this.$http({
        url: "/index/setList",
      }).then((res) => {
        console.log(res, "111");
        // this.navigation = res.data.menu;
        this.Phone = res.data.home_phone;
        this.cz = res.data.home_cz;
        this.emil = res.data.home_email;
      });
    },
  },
  components: {
    Footer,
  },
};
</script>

<style lang="less" scoped>
.search {
  .search-nav {
    width: 100%;
    height: 36px;
    // background-color: #dcdcdc;
    .index-top {
      display: flex;
      align-items: center;
      width: 1200px;
      height: 121px;
      padding: 23px 0 23px 0;
      margin: 0 auto;
      > img:nth-child(1) {
        width: 309px;
        height: 90px;
        margin-right: 520px;
        cursor: pointer;
      }
      .top-search {
        display: flex;
        align-items: center;
        width: 350px;
        height: 56px;
        margin-right: 32px;
        border: 1px solid #bdd4ff;
        > input:nth-child(1) {
          border: none;
          outline: none;
          width: 300px;
          height: 56px;
          text-indent: 1.5em;
        }
        > img:nth-child(2) {
          width: 28px;
          height: 28px;
        }
      }
      .top-link {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 121px;
        color: #2050ac;
        > div:nth-child(1) {
          display: flex;
          align-items: center;
          margin: 10px 0 15px 29px;
          font-size: 21px;
          white-space: nowrap;
          > img:nth-child(1) {
            width: 23px;
            height: 31px;
            margin-right: 25px;
          }
        }
        > div:nth-child(2) {
          display: flex;
          align-items: center;
          margin: 0px 0 15px 29px;
          font-size: 21px;
          white-space: nowrap;
          > img:nth-child(1) {
            width: 32px;
            height: 32px;
            margin-right: 21px;
          }
        }
        > div:nth-child(3) {
          display: flex;
          align-items: center;
          margin: 0px 0 10px 29px;
          font-size: 21px;
          white-space: nowrap;
          > img:nth-child(1) {
            width: 31px;
            height: 28px;
            margin-right: 21px;
          }
        }
      }
    }
    .list {
      width: 1200px;
      margin: 0 auto 59px;
    }
    .index-navigation {
      width: 1200px;
      height: 58px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto 52px;
      background-color: #2050ac;
      color: #ffffff;
      span {
        height: 58px;
        display: block;
        flex: 1;
        font-size: 18px;
        text-align: center;
        line-height: 58px;
        cursor: pointer;
      }
    }
    .nav-item {
      width: 1200px;
      height: 36px;
      line-height: 36px;
      margin: 0 auto 17px;
      > span {
        font-size: 12px;
        margin: 0 28px;
        color: #222222;
        cursor: pointer;
      }
    }
    .search-top {
      display: flex;
      align-items: center;
      width: 1200px;
      margin: 0 auto 36px;
      > img:nth-child(1) {
        width: 162px;
        height: 46px;
        margin-right: 41px;
      }
      > div:nth-child(2) {
        display: flex;
        align-items: center;
        > input:nth-child(1) {
          width: 258px;
          height: 32px;
          outline: none;
          text-indent: 1.2em;
        }
        > span:nth-child(2) {
          display: inline-block;
          width: 50px;
          height: 36px;
          text-align: center;
          line-height: 32px;
          background-color: #2050ac;
          color: #fff;
          font-size: 16px;
        }
      }
    }
    > div:nth-child(3) {
      width: 1200px;
      margin: 0 auto 37px;
      font-size: 12px;
      color: #999999;
    }
    .search-list {
      width: 1200px;
      margin: 0 auto 59px;
      .item-list {
        margin-bottom: 39px;
        border-bottom: 1px solid #dcdcdc;
        > div:nth-child(1) {
          font-size: 18px;
          margin-bottom: 15px;
          color: #333333;
          > span:nth-child(1) {
            color: #ff0000;
          }
          > span:nth-child(1):hover {
          }
        }
        > span:nth-child(2) {
          display: block;
          margin-bottom: 19px;
          font-size: 14px;
          color: #666666;
        }
        > div:nth-child(3) {
          margin-bottom: 29px;
          font-size: 14px;
          color: #666666;
          > span:nth-child(2) {
            color: #ff0000;
          }
        }
      }
    }
    .pageDown {
      width: 1200px;
      margin: 0px auto 30px;
      text-align: center;
    }
  }
}
.noList {
  width: 1200px;
  top: 2000px;
  font-size: 26px !important;
  text-align: center;
  margin-bottom: 100px;
  color: #000 !important;
}
</style>
